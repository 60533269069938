<template>
    <div>
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="nav-icon fas fa-house-user"></i> Simuladores <small>Formulário de Cadastro</small></h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="nav-icon fas fa-home"></i> Home
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active"><i class="nav-icon fas fa-house-user"></i> Simuladores</li>
                        </ol>
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline">
                    <form id="form1" class="form-horizontal">
                        <div class="card-body">
                            <div class="col-xs-12 col-sm-12 col-lg-6">
                                <h4>Dados do Simulador</h4>
                                <div class="form-group row">
                                    <label for="telefone" class="col-sm-2 col-form-label">Logo</label>
                                    <div class="col-xs-6 col-sm-6 col-lg-4">
                                        <image-editor v-model="entity.logo" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="site" class="col-sm-2 col-form-label">Site</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control validate[required]" id="site" name="site" v-model="entity.site" size="50" maxlength="150" placeholder="Site" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="email" class="col-sm-2 col-form-label">-Email</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control validate[required]" id="email" name="email" v-model="entity.email" size="50" maxlength="150" placeholder="Email" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="senha" class="col-sm-2 col-form-label">Senha</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control validate[required]" id="senha" name="senha" v-model="entity.senha" size="20" maxlength="20" placeholder="Senha" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="telefone" class="col-sm-2 col-form-label">Telefone</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control " id="telefone" name="telefone" v-model="entity.telefone" size="20" maxlength="20" placeholder="Telefone" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="ambiente" class="col-sm-2 col-form-label">Ambiente</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="ambiente" name="ambiente" v-model="entity.ambiente" size="20" maxlength="20" placeholder="Ambiente" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="marcaAtiva" class="col-sm-2 col-form-label">Marca</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="marcaAtiva" name="marcaAtiva" v-model="entity.marcaAtiva" size="50" maxlength="50" placeholder="Marcaativa" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="surface01qs" class="col-sm-2 col-form-label">Superfície 01</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="surface01qs" name="surface01qs" v-model="entity.surface01qs" size="20" maxlength="20" placeholder="Surface01qs" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="surface02qs" class="col-sm-2 col-form-label">Superfície 02</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="surface02qs" name="surface02qs" v-model="entity.surface02qs" size="20" maxlength="20" placeholder="Surface02qs" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="surface01qsName" class="col-sm-2 col-form-label">Nome da Superfície 01</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="surface01qsName" name="surface01qsName" v-model="entity.surface01qsName" size="50" maxlength="50" placeholder="Surface01qsname" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="surface02qsName" class="col-sm-2 col-form-label">Nome da Superfície 02</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control " id="surface02qsName" name="surface02qsName" v-model="entity.surface02qsName" size="50" maxlength="50" placeholder="Surface02qsname" />
                                    </div>
                                </div>

                                <h4>Status</h4>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label"></label>
                                    <div class="col-sm-10">
                                        <div class="radio">
                                            <label class="form-radio form-icon">
                                                <input type="radio" name="status_arquiteto" data-icheck="green" v-model="entity.status_arquiteto" v-bind:value="true" />
                                                Ativo
                                            </label>
                                            <label class="form-radio form-icon">
                                                <input type="radio" name="status_arquiteto" data-icheck="red" v-model="entity.status_arquiteto" v-bind:value="false" />
                                                Inativo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="card-footer">
                        <div class="offset-sm-1 col-sm-11">
                            <button type="button" v-if="this.$route.params.id == 0" v-on:click="insertEntity" class="btn btn-success btn-social"> <i class="fas fa-check"></i> Inserir</button>
                            <button type="button" v-if="this.$route.params.id != 0" v-on:click="updateEntity" class="btn btn-info btn-social"> <i class="fas fa-pencil-alt"></i> Alterar</button>
                            <button type="button" v-if="this.$route.params.id != 0" v-on:click="deleteEntity" class="btn btn-danger btn-social"> <i class="fas fa-times"></i> Excluir</button>
                            <button type="button" v-on:click="back" class="btn btn-default btn-social"><i class="fas fa-history"></i> Voltar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import ArquitetoController from "@/controllers/arquiteto.controller";

    export default {
        data: function () {
            return {
                entity: {}
            };
        },
        mounted() {
            this.$validator.validate("#form1");
            const id = parseInt(this.$route.params.id, 10);
            this.loadEntity(id);
            $(":input").inputmask();
        },
        methods: {
            loadEntity: function (id) {

                if (id === 0)
                    return;

                ArquitetoController.getById(id)
                    .then(response => {
                        this.entity = response.data.result;
                    });
            },
            insertEntity: function (event) {
                if (!this.$validator.isValid("#form1"))
                return;

                event.target.disabled = true;

                let vm = this;

                ArquitetoController.insert(vm.entity)
                    .then(response => {
                        if (!response.data.success) {
                            vm.$toastr.error(response.data.error, 'Erro!', {
                                timeOut: 8000,
                                onHidden: function () {
                                    event.target.disabled = false;
                                }
                            });
                            return;
                        }

                        vm.$toastr.success("Dados cadastrados com sucesso.", "Aviso",
                            {
                                onHidden: function () {
                                    event.target.disabled = false;
                                    vm.back();
                                }
                            }
                        );
                    })
                    .catch(e => {
                        event.target.disabled = false;
                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                    });
            },
            updateEntity: function (event) {
                if (!this.$validator.isValid("#form1"))
                return;

                event.target.disabled = true;

                let vm = this;

                ArquitetoController.update(vm.entity)
                    .then(response => {
                        if (!response.data.success) {
                            vm.$toastr.error(response.data.error, 'Erro!', {
                                timeOut: 8000,
                                onHidden: function () {
                                    event.target.disabled = false;
                                }
                            });
                            return;
                        }

                        vm.$toastr.success("Dados alterados com sucesso.", "Aviso",
                            {
                                onHidden: function () {
                                    event.target.disabled = false;
                                    vm.back();
                                }
                            });
                    })
                    .catch(e => {
                        event.target.disabled = false;
                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                    });
            },
            deleteEntity: function (event) {
                event.target.disabled = true;

                let vm = this;

                vm.$dialogs.confirm({
                    title: 'Confirmar Exclusão!',
                    content: 'Deseja realmente excluir estes dados?',
                    icon: 'fa fa-exclamation-triangle ',
                    type: 'red',
                    buttons: {
                        confirm: {
                            text: 'Confirmar',
                            btnClass: 'btn-red',
                            action: function () {
                                event.target.disabled = true;

                                ArquitetoController.delete(vm.entity)
                                    .then(response => {

                                        if (!response.data.success) {
                                            vm.$toastr.error(response.data.error, 'Erro!', {
                                                timeOut: 8000,
                                                onHidden: function () {
                                                    event.target.disabled = false;
                                                }
                                            });
                                            return;
                                        }

                                        vm.$toastr.success("Dados excluídos com sucesso.", "Aviso",
                                            {
                                                onHidden: function () {
                                                    vm.back();
                                                }
                                            }
                                        );
                                    })
                                    .catch(e => {
                                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                                        event.target.disabled = false;
                                    });
                            }
                        },
                        close: {
                            text: 'Cancelar'
                        }
                    }
                });
                event.target.disabled = false;
            },
            back: function () {
                this.$router.push({ name: "list-arquiteto" });
            }
        }
    };
</script>

<style scoped>
</style>