import http from '@/utils/http';

export default {
  async getById(id) {
    return await http.get(`/api/arquiteto/get/${id}`);
  },
  async insert(data) {
    return await http.post('/api/arquiteto/insert', data);
  },
  async update(data) {
    return await http.post('/api/arquiteto/update', data);
  },
  async delete(data) {
    return await http.post('/api/arquiteto/delete', data);
  }
};